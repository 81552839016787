export default function(instance) {
	return {
		load_questions(params) {
			return instance({
				url: "/dispatcher/questions",
				method: "get",
				params,
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		save_description(id, data) {
			return instance({
				url: `/v2/dispatcher/questions/ksk/${id}`,
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				method: "post",
				data
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},

		load_description(id) {
			return instance({
				url: `/v2/dispatcher/questions/?house_id=${id}`,
				method: "get",
			})
				.then(res => res.data)
				.catch(err => {
					throw err;
				});
		},
	};
}
